<script setup lang="ts">
import VInstallAppBanner from "@magnit/core/src/containers/VInsallAppBanner/VInstallAppBanner.vue";
import { vElementVisibility } from "@vueuse/components";
import AdvantageIcon from "~/assets/svg/icons/advantage.svg";
import { MODALS } from "~/stores/appModals";

const pwaStore = usePwaStore();
const platformStore = usePlatformStore();
const modalsStore = useAppModalsStore();
const { send } = useAnalytics();
const { open: openPwaIosInstruction } = usePwaIosInstructionModal();

const isElementVisible = ref(false);
const visibilityEventEmitted = ref(false);
const isClosed = ref(false);
const showOpenButton = computed(
  () => pwaStore.state.appInstalled && !modalsStore.isOpen(MODALS.InstallSuccess),
);

const showBanner = computed(
  () =>
    !isClosed.value &&
    !platformStore.isPwa &&
    ((platformStore.showAndroidAppBanner && pwaStore.state.deferredInstallPrompt) ||
      platformStore.showIosAppBanner ||
      pwaStore.state.installPromptBannerOpen ||
      pwaStore.state.showIosTutorial ||
      showOpenButton.value),
);

const onClose = () => {
  pwaStore.state.installPromptBannerOpen = false;
  pwaStore.state.showIosTutorial = false;
  isClosed.value = true;
  send("App:Prompt:Banner:Cancel");
};

const onOpenPwaClick = () => {
  if (platformStore.platformInfo.pwaLinkSupported) {
    window.open(pwaStore.DEFAULT_PWA_LINK, "_blank");
  }
};

const onButton = () => {
  send("App:Prompt:Banner:Click");
  if (pwaStore.state.showIosTutorial) {
    openPwaIosInstruction();
    return;
  }
  pwaStore.showPrompt();
};

const onElementVisibility = (visibility: boolean) => {
  isElementVisible.value = visibility;
};

const onEventsTriggering = () => {
  if (pwaStore.state.installPromptBannerOpen || pwaStore.state.showIosTutorial) {
    visibilityEventEmitted.value = true;
    send("App:Prompt:Banner:View");
  } else if (showOpenButton.value) {
    visibilityEventEmitted.value = true;
  }
};

watch(isElementVisible, (next) => {
  if (next && !visibilityEventEmitted.value) {
    onEventsTriggering();
  }
});

watch(showOpenButton, (next) => {
  if (visibilityEventEmitted.value && next) {
    send("App:Open:Banner:View");
  }
});

onMounted(() => {
  if (isElementVisible.value) {
    onEventsTriggering();
  }
});
</script>

<template>
  <VInstallAppBanner
    v-element-visibility="onElementVisibility"
    title="Магнит Онлайн"
    subtitle="Иконка для входа"
    :open="showBanner"
    :button-title="pwaStore.state.appInstalled ? 'Открыть' : 'Добавить'"
    :loading="pwaStore.state.installInProgress"
    :class="{ 'android-app-banner': platformStore.showAndroidAppBanner }"
    bottom-shadow
    @click="pwaStore.state.appInstalled ? onOpenPwaClick() : onButton()"
    @close="onClose"
  >
    <template #icon>
      <AdvantageIcon />
    </template>
  </VInstallAppBanner>
</template>

<style lang="postcss">
.android-app-banner {
  @media all and (display-mode: standalone) {
    display: flex;
  }
}
</style>
