<script setup lang="ts">
import { useNetwork } from "@vueuse/core";
import VTopButton from "@magnit/core/src/components/VTopButton/VTopButton.vue";
import VCookiePolicyPopup from "@magnit/unit-cookie-policy/src/VCookiePolicyPopup.vue";
import VLayout from "~/components/VLayout.vue";
import VInstallAppBanner from "~/components/VInstallAppBanner.vue";
import VInstallCompleteModal from "~/containers/Modals/VInstallCompleteModal.vue";

const { isAuthorized } = useAuth();
const userStore = useUserStore();
const pushStore = usePushStore();
const pwaStore = usePwaStore();
const modalsStore = useAppModalsStore();
const abStore = useAbStore();
const toasts = useToasts();
const platformStore = usePlatformStore();
const { loadSlotApiInit } = useSlotsStore();
const {
  public: { favoriteSectionEnabled, kfpCid },
} = useRuntimeConfig();

useFirebaseInit();

const network = reactive(useNetwork());

const showCookie = ref(false);
const cookieTimerId = ref<NodeJS.Timeout | null>(null);

// todo вынести в стору?
const getProfileSummary = async () => {
  await userStore.getProfile();
  Promise.all([
    userStore.getPermissions(),
    abStore.getExperiments(),
    favoriteSectionEnabled
      ? userStore.getFavoriteCategoriesNew()
      : userStore.getFavoriteCategories(),
  ]);
};

watch(isAuthorized, async (next) => {
  if (next) {
    await getProfileSummary();
  } else {
    userStore.$reset();
    userStore.clearSavedUUID();
  }
});

onBeforeMount(async () => {
  if (window.location.host === "my.magnit.ru") {
    document.cookie = "mg_at=; Max-Age=-99999999;";
  }
});

onMounted(async () => {
  addEventListener("beforeinstallprompt", (e: Event) => {
    e.preventDefault();
    pwaStore.state.deferredInstallPrompt = e;
    pwaStore.state.installPromptBannerOpen = true;
  });
  const platformInfo = getPlatformInfo();
  if (platformInfo) {
    platformStore.setValues(platformInfo);
  }
  if (await pwaStore.checkAppInstall()) {
    pwaStore.state.appInstalled = true;
  }
});

onMounted(async () => {
  if (isAuthorized.value) {
    await getProfileSummary();
  }
  nextTick(async () => {
    await pushStore.updateToken();
  });
});

onMounted(() => {
  loadSlotApiInit();
  loadScript(`/scripts/${kfpCid}.das.obf.js`);
  document.body.classList.toggle("device_ios", platformStore.platformInfo.osName === "ios");
  if (platformStore.platformInfo.osName === "ios" && !platformStore.platformInfo.pwa) {
    pwaStore.state.showIosTutorial = true;
  }
});

onMounted(() => {
  cookieTimerId.value = setTimeout(() => {
    if (!modalsStore.openedModals.length) {
      showCookie.value = true;
    }
  }, 2000);
});

watch(
  () => network.isOnline,
  (isOnline) => {
    if (!isOnline) {
      toasts.warning({ text: "Нет интернета. Проверьте подключение к сети." });
    }
  },
);

watch(
  () => modalsStore.openedModals.length,
  (next) => {
    if (next) {
      if (cookieTimerId.value) {
        clearTimeout(cookieTimerId.value);
      }
      showCookie.value = false;
    } else {
      cookieTimerId.value = setTimeout(() => {
        if (!modalsStore.openedModals.length) {
          showCookie.value = true;
        }
      }, 2000);
    }
  },
);
</script>

<template>
  <VInstallAppBanner />

  <VLayout>
    <slot />
    <VCookiePolicyPopup :hide="!showCookie || !!$route.meta?.hideCookiePopup" />
    <VInstallCompleteModal />
    <LProfileModalPwaIosInstruction />
    <VTopButton class="app-top-button" />
  </VLayout>
</template>

<style scoped lang="postcss">
.app-root {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.app-top-button.pl-button {
  position: fixed;
  z-index: var(--pl-z-index-overlay);
}
</style>
