<script setup lang="ts">
import { useRoute } from "vue-router";
import NavBar from "~/components/VNavBar.vue";

const route = useRoute();
const rootRef = ref<HTMLDivElement>();
const { isAuthorized } = useAuth();

const isNavBarShow = computed(
  () =>
    isAuthorized.value &&
    route.name !== "404" &&
    route.path !== Routes.Landing &&
    route.path !== Routes.Chat &&
    route.name !== Routes.ProfileIncomplete &&
    route.path !== Routes.CookiePolicy &&
    !route.path.includes(Routes.PaymentMethods) &&
    route.name !== "coupons-couponId",
);
</script>

<template>
  <div ref="rootRef" class="main" :class="{ 'main_with-navbar': isNavBarShow }">
    <slot />
  </div>
  <NavBar v-show="isNavBarShow" />
</template>

<style scoped lang="postcss">
.main {
  display: flex;
  flex: 1;
  justify-content: center;

  &_with-navbar {
    padding-bottom: var(--navbar-height);
  }
}
</style>
