import Bowser from "bowser";
/*
/ веб-пуши доступны в версиях ios начиная с 16.4
*/
const PUSH_SUP_IOS_MAJOR_V = 16;
const PUSH_SUP_IOS_MINOR_V = 4;
const ANDROID_APP_MIN_MAJOR = 7;
export const ANDROID_APP_SMART_LINK =
  "https://redirect.appmetrica.yandex.com/serve/28712210658241993";

const OLD_IOS_MAJOR_V = 15;

const getPlatformInfo = () => {
  if (typeof window === "undefined") {
    return null;
  }

  const platform = Bowser.getParser(window.navigator.userAgent);
  const os = platform.getOS();
  const browser = platform.getBrowser();
  const platformType = platform.getPlatformType();

  let pushSupportingIosVersion = false;
  let oldIosVersion = false;
  let versionMajor = 0;
  let versionMinor = 0;

  const osVersion =
    String(os.versionName || os.version).replace(/[^0-9.]/g, "") || "";

  const osName = os.name?.toLowerCase() || "";

  if (osVersion) {
    const versionNumbers = osVersion.split(".");
    versionMajor = Number(versionNumbers[0] || 0);
    versionMinor = Number(versionNumbers[1] || 0);
  }

  if (osName === "ios" && osVersion) {
    pushSupportingIosVersion = Boolean(
      versionMajor >= PUSH_SUP_IOS_MAJOR_V + 1 ||
      (versionMajor === PUSH_SUP_IOS_MAJOR_V &&
        versionMinor >= PUSH_SUP_IOS_MINOR_V),
    );
    oldIosVersion = versionMajor <= OLD_IOS_MAJOR_V;
  }
  const browserName = browser.name?.toLowerCase() || "";

  return {
    platformType,
    osName,
    osVersion,
    pushSupportingIosVersion,
    oldIosVersion,
    browserName,
    browserVersion: browser.version || "",
    pwa: window.matchMedia("(display-mode: standalone)").matches,
    pwaLinkSupported:
      (browserName === "chrome" || browserName === "edge") &&
      osName === "android",
    mobile: ["mobile", "tablet"].includes(platformType),
    androidAppCompatible:
      osName === "android" && versionMajor >= ANDROID_APP_MIN_MAJOR,
  };
};

export default getPlatformInfo;
